import * as React from "react";

const SvgIcon = ( ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    fill="none"
    viewBox="0 0 22 20"
  >
    <path
      stroke="#838086"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18.5c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
    ></path>
    <path
      stroke="#838086"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m16 7-3.13 2.5c-1.03.82-2.72.82-3.75 0L6 7"
    ></path>
  </svg>
);

export default SvgIcon;
