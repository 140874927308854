export function isValidEmail(email: string) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
}

export function isValidPhoneNumber(phoneNumber: string) {
    const phonePattern = /^\d{9,15}$/;
    return phonePattern.test(phoneNumber);
}

export function isValidName(name: string) {
  const namePattern = /^[A-ZĄĘŁŻŹĆ][a-ząęłżźć]*$/;
  return namePattern.test(name);
}

export function isValidSurname(name: string) {
  const namePattern = /^[A-ZĄĘŁŻŹĆ]([a-ząęłżźć])*(-[A-ZĄĘŁŻŹĆ][a-ząęłżźć]*)?$/;
  return namePattern.test(name);
}



export function validateEmailInput(
    event: React.ChangeEvent<HTMLInputElement>,
    setState: (data: string) => void
  ): void {
    const value = event.target.value.toString();
    const allowedCharacters = /^[a-zA-Z0-9._@-]*$/;
  
    // Aktualizuj stan tylko, jeśli wpisany znak pasuje do wyrażenia regularnego
    if (allowedCharacters.test(value)) {
      setState(value);
    }
  }