import * as React from "react";

interface IconProps {
  className: string
}

const SvgIcon: React.FC<IconProps> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
  >
    <path
      className={className}
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M14.5 9.3v2.9h2.6c.2 0 .3.2.3.4l-.4 1.9c0 .1-.2.2-.3.2h-2.2V22h-3v-7.2H9.8q-.3 0-.3-.3v-1.9q0-.3.3-.3h1.7V9c0-1.7 1.3-3 3-3h2.7q.3 0 .3.3v2.4q0 .3-.3.3h-2.4q-.3 0-.3.3Z"
    ></path>
    <path
      className={className}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.5 22h-6c-5 0-7-2-7-7V9c0-5 2-7 7-7h6c5 0 7 2 7 7v6c0 5-2 7-7 7"
    ></path>
  </svg>
);

export default SvgIcon;
