import * as React from "react";

const SvgIcon = ( ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="83"
    height="83"
    fill="none"
    viewBox="0 0 83 83"
  >
    <circle cx="41.5" cy="41.5" r="41.5" fill="#242226"></circle>
    <path
      stroke="#F7F7F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M33.917 54.042c-4.25 0-7.084-2.125-7.084-7.084v-9.916c0-4.959 2.834-7.084 7.084-7.084h14.167c4.25 0 7.083 2.125 7.083 7.084v9.916c0 4.959-2.834 7.084-7.084 7.084z"
    ></path>
    <path
      stroke="#F7F7F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m48.083 37.75-4.434 3.542c-1.46 1.161-3.853 1.161-5.312 0l-4.42-3.542"
    ></path>
  </svg>
);

export default SvgIcon;
