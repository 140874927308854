import * as React from "react";

const SvgIcon = ( ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#838086"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10"
    ></path>
    <path
      stroke="#838086"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m15.71 15.18-3.1-1.85c-.54-.32-.98-1.09-.98-1.72v-4.1"
    ></path>
  </svg>
);

export default SvgIcon;
