interface IconProps {
    className: string
  }

const TikTok: React.FC<IconProps> = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
    className={className}
      strokeLinejoin="round"
      d="M16.822 5.134A4.75 4.75 0 0 1 15.648 2h-.919m2.093 3.134a4.77 4.77 0 0 0 3.605 1.649v3.436a8.17 8.17 0 0 1-4.78-1.537v6.989c0 3.492-2.839 6.329-6.323 6.329-1.824 0-3.47-.78-4.626-2.02A6.3 6.3 0 0 1 3 15.67c0-3.44 2.756-6.245 6.17-6.32m7.652-4.216-.054-.035M6.986 17.352a2.86 2.86 0 0 1-.548-1.686 2.89 2.89 0 0 1 2.886-2.888c.297 0 .585.05.854.134v-3.51a6 6 0 0 0-.854-.06c-.051 0-.462.027-.513.027M14.724 2H12.21l-.005 13.778a2.89 2.89 0 0 1-2.881 2.781 2.9 2.9 0 0 1-2.343-1.203"
    ></path>
  </svg>
);

export default TikTok;
