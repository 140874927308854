import * as React from "react";

const SvgIcon = ( ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="83"
    height="83"
    fill="none"
    viewBox="0 0 83 83"
  >
    <circle cx="41.5" cy="41.5" r="41.5" fill="#242226"></circle>
    <path
      stroke="#F7F7F7"
      strokeWidth="2"
      d="M57.524 54.614a7 7 0 0 1-1.156 1.734c-.833.918-1.751 1.581-2.788 2.006a8.5 8.5 0 0 1-3.315.646c-1.734 0-3.587-.408-5.542-1.241s-3.91-1.955-5.848-3.366a49 49 0 0 1-5.576-4.76 48 48 0 0 1-4.743-5.559c-1.394-1.938-2.516-3.876-3.332-5.797Q24 35.37 24 32.718c0-1.156.204-2.261.612-3.281.408-1.037 1.054-1.989 1.955-2.839C27.655 25.527 28.845 25 30.103 25c.476 0 .952.102 1.377.306.442.204.833.51 1.139.952l3.944 5.559c.306.425.527.816.68 1.19.153.357.238.714.238 1.037 0 .408-.119.816-.357 1.207-.221.391-.544.799-.952 1.207l-1.292 1.343a.9.9 0 0 0-.272.68c0 .136.017.255.051.391.051.136.102.238.136.34.306.561.833 1.292 1.581 2.176a58 58 0 0 0 2.465 2.686 51 51 0 0 0 2.703 2.499c.884.748 1.615 1.258 2.193 1.564.085.034.187.085.306.136.136.051.272.068.425.068.289 0 .51-.102.697-.289l1.292-1.275c.425-.425.833-.748 1.224-.952a2.26 2.26 0 0 1 1.207-.357c.323 0 .663.068 1.037.221s.765.374 1.19.663l5.627 3.995c.442.306.748.663.935 1.088.17.425.272.85.272 1.326 0 .612-.136 1.241-.425 1.853Z"
    ></path>
  </svg>
);

export default SvgIcon;
