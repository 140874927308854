import * as React from "react";

const SvgIcon = ( ) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="83"
    height="83"
    fill="none"
    viewBox="0 0 83 83"
  >
    <circle cx="41.5" cy="41.5" r="41.5" fill="#242226"></circle>
    <path
      stroke="#F7F7F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M50.718 53.32c.142 1.175-1.119 1.997-2.125 1.388l-5.935-3.528q-.978 0-1.913-.127c1.049-1.233 1.672-2.791 1.672-4.477 0-4.023-3.485-7.282-7.792-7.282-1.643 0-3.16.468-4.42 1.29a9 9 0 0 1-.057-1.077c0-6.446 5.596-11.674 12.51-11.674s12.509 5.228 12.509 11.674c0 3.825-1.97 7.21-5.001 9.336z"
    ></path>
    <path
      stroke="#F7F7F7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M40.745 51.053c-1.402 1.7-3.626 2.79-6.12 2.79l-3.697 2.196c-.624.383-1.417-.141-1.332-.864l.354-2.79c-1.898-1.318-3.116-3.43-3.116-5.81 0-2.492 1.331-4.688 3.371-5.992a8.05 8.05 0 0 1 4.42-1.289c4.307 0 7.792 3.259 7.792 7.282 0 1.686-.623 3.244-1.672 4.477"
    ></path>
  </svg>
);

export default SvgIcon;
