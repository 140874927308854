import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="#838086"
      strokeWidth="2"
      d="M12 13.43a3.12 3.12 0 1 1 0-6.24 3.12 3.12 0 0 1 0 6.24Z"
      clipRule="evenodd"
    ></path>
    <path
      stroke="#838086"
      strokeWidth="2"
      d="M20.38 8.5c1.15 5.08-2.01 9.38-4.78 12.04a5.193 5.193 0 0 1-7.21 0c-2.76-2.66-5.92-6.97-4.77-12.05 1.97-8.66 14.8-8.65 16.76.01Z"
    ></path>
  </svg>
);

export default SvgIcon;
